import { useQuery } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import { Spinner, Tabs } from "react-bootstrap";
import { priceCell, security, TypeCell } from "../../components/Cells";
import { Tab } from "@headlessui/react";
import {
  Block,
  BlockHeader,
  BlockList,
  BlockListItem,
} from "../../components/Block";
import { useState } from "react";
import { Assets, AssetsTable } from "../assets/Assets";

export function Planets({ system_id }) {
  const { data, isLoading } = useQuery({
    queryKey: ["planets", system_id],
    queryFn: () => {
      return fetch(`/api/universe/systems/${system_id}/planets/?expand=item`).then((res) => res.json());
    },
  });

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (data.results?.length === 0) {
    return (
      <div className={"alert alert-info"}>No planets found in this system.</div>
    );
  }

  return (
    <>
      {data.map((planet) => {
        return (
          <Block key={planet.id} className={"mt-3"}>
            <BlockHeader>
              <strong>{planet.name}</strong>
            </BlockHeader>
            <BlockList>
              <BlockListItem>
                <em>Type</em>
                <div>
                  <img
                    src={`https://images.evetech.net/types/${planet.item.id}/icon?size=32`}
                    alt={""}
                    className={"me-2"}
                  />
                  <span>{planet.item.name}</span>
                </div>
              </BlockListItem>
            </BlockList>
          </Block>
        );
      })}
    </>
  );
}

export function Stations({ system_id }) {
  const { data, isLoading } = useQuery({
    queryKey: ["stations", system_id],
    queryFn: () => {
      return fetch(
        `/api/universe/stations/?system_id=${system_id}&expand=structure_type`,
      ).then((res) => res.json());
    },
  });

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  if (data.results.length === 0) {
    return (
      <div className={"alert alert-info"}>
        No stations found in this system.
      </div>
    );
  }

  return (
    <>
      {data.results.map((station) => {
        return (
          <Block key={station.id} className={"mt-3"}>
            <BlockHeader>
              <strong>{station.name}</strong>
            </BlockHeader>
            <BlockList>
              {station.station_type === 20 && (
                <BlockListItem>
                  <em>Structure Type</em>
                  <div>
                    <TypeCell
                      id={station.structure_type.id}
                      name={station.structure_type.name}
                    />
                  </div>
                </BlockListItem>
              )}
              {station.office_rental_cost && (
                <BlockListItem>
                  <em>Office Rent</em>
                  <div>{priceCell(station.office_rental_cost)}</div>
                </BlockListItem>
              )}
              {station.reprocessing_efficiency && (
                <BlockListItem>
                  <em>Reprocessing Efficiency</em>
                  <div>{station.reprocessing_efficiency}</div>
                </BlockListItem>
              )}
              {station.reprocessing_stations_take && (
                <BlockListItem>
                  <em>Reprocessing Stations Take</em>
                  <div>{station.reprocessing_stations_take}</div>
                </BlockListItem>
              )}
            </BlockList>
          </Block>
        );
      })}
    </>
  );
}

export function System() {
  const { id } = useParams();
  const [key, setKey] = useState("stations");
  const { data, isLoading } = useQuery({
    queryKey: ["system", id],
    queryFn: () => {
      return fetch(`/api/universe/systems/${id}/`).then((res) => res.json());
    },
  });

  if (isLoading) {
    return (
      <div className={"p-4"}>
        <div className={"text-center"}>
          <Spinner animation={"grow"} role={"status"}>
            <span className={"visually-hidden"}>Loading...</span>
          </Spinner>
        </div>
      </div>
    );
  }

  return (
    <div className={"p-4 vh-100 d-flex flex-column"}>
      <div>
        <h1 className={"h3 mb-3 fw-normal"}>
          {security(data.security)} - {data.name}
        </h1>
        <nav aria-label={"breadcrumb"}>
          <ol className={"breadcrumb"}>
            <li className={"breadcrumb-item"}>
              <Link to={"/universe/"}>Universe</Link>
            </li>
            <li className={"breadcrumb-item active"} aria-current={"page"}>
              {data.name}
            </li>
          </ol>
        </nav>
      </div>
      <div
        className={
          "flex-grow-1 d-flex flex-column overflow-y-auto tab-container"
        }
      >
        <Tabs defaultActiveKey={"stations"} onSelect={setKey}>
          <Tab eventKey={"stations"} title={"Stations"}>
            <div className={"flex-grow-1 d-flex flex-column overflow-y-auto"}>
              <Stations system_id={id} />
            </div>
          </Tab>
          <Tab eventKey={"planets"} title={"Planets"}>
            {key === "planets" && <Planets system_id={id} />}
          </Tab>
          <Tab eventKey={"assets"} title={"Assets"}>
            {key === "assets" && (
              <AssetsTable filters={[{ type: "location", value: id }]} />
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
}
