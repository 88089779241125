import {Content, Header, Page} from "../../components/Page";
import React, {useEffect, useMemo, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {Table} from "../../components/Table";
import {security, toPercent} from "../../components/Cells";
import {Link} from "react-router-dom";
import {applyFiltersToURL, applySortingToURL, FilterBuilder} from "../../components/FilterBuilder";
import {AutocompleteInput} from "../components/AutocompleteInput";
import {useStorage} from "../../hooks/useStorage";

export function Universe() {
  const [url, setURL] = useState(undefined);
  const [sorting, setSorting] = useState([
    {id: "region", desc: false},
  ]);
  const [filters, setFilters] = useStorage("universe.filters", []);

  useEffect(() => {
    setURL(undefined);
  }, [filters, sorting]);

  const systemsURL = useMemo(() => {
    const params = new URLSearchParams();

    params.append("fields", [
      "id",
      "name",
      "index_manufacturing",
      "index_copying",
      "index_invention",
      "index_me",
      "index_te",
      "index_reaction",
      "planet_count",
      "region.name",
      "security"
    ].join(","));

    params.append("expand", [
      "region",
    ].join(","));

    if (filters) {
      applyFiltersToURL(params, filters);
    }

    if (sorting) {
      applySortingToURL(params, sorting);
    }

    return `/api/universe/systems/?${params.toString()}`;
  }, [filters, sorting]);

  const { data: systems, isLoading } = useQuery({
    queryKey: ["systems", url, systemsURL],
    queryFn: () => {
      return fetch(url || systemsURL).then((res) => res.json());
    }
  });

  function costIndex(label, key) {
    return {
      id: key,
      header: label,
      accessorKey: key,
      meta: {
        align: "end",
      },
      cell: (cell) => {
        const value = cell.getValue();
        return (
          <span
            className={[
              value < 0.05 ? "text-success" : "",
              value > 0.05 && value < 0.1 ? "text-warning" : "",
              value > 0.1 ? "text-danger" : "",
            ].join(" ")}
          >
              {toPercent(value)}
            </span>
        );
      },
    };
  }

  return (
    <Page>
      <Content>
        <Header title={"Universe"} />
        <FilterBuilder
          filters={filters}
          onChange={setFilters}
          allowedFilters={{
            name: {
              label: "Name",
              operators: ["~~", "!~"],
            },
            region: {
              label: "Region",
              operators: ["==", "!="],
              component: AutocompleteInput,
              props: {
                type: "regions"
              }
            },
            planets: {
              label: "Planets",
              operators: ["<<", "<=", "==", "!=", ">=", ">>"],
            },
            security: {
              label: "Security Status",
              operators: ["<<", "<=", "==", "!=", ">=", ">>"],
            }
          }}
        />
        <Table
          rows={systems?.results || []}
          columns={[
            {
              header: "Name",
              accessorKey: "name",
              cell: (cell) => {
                const value = cell.getValue();
                const id = cell.row.original.id;
                return (
                  <>
                    {security(cell.row.original.security)}&nbsp;
                    <Link to={`/universe/system/${id}/`}>{value}</Link>
                  </>
                );
              }
            },
            {
              id: "region_id",
              header: "Region",
              accessorKey: "region.name",
            },
            {
              header: "Planets",
              accessorKey: "planet_count",
              meta: {
                align: "center",
              }
            },
            costIndex("Manufacturing", "index_manufacturing"),
            costIndex("Copying", "index_copying"),
            costIndex("Invention", "index_invention"),
            costIndex("ME", "index_me"),
            costIndex("TE", "index_te"),
            costIndex("Research", "index_reaction"),
          ]}
          isLoading={isLoading}
          sorting={sorting}
          setSorting={setSorting}
          manualSorting={true}
        />
        <div className={"my-4"}>
          {systems?.previous && (
            <button
              className="btn btn-primary me-2"
              onClick={() => setURL(systems.previous)}
            >
              Previous
            </button>
          )}
          {systems?.next && (
            <button
              className="btn btn-primary"
              onClick={() => setURL(systems.next)}
            >
              Next
            </button>
          )}
        </div>
      </Content>
    </Page>
  );
}

// export function Universe() {
//   const [selectedSystem, setSelectedSystem] = useState(null);
//
//   return (
//     <Page>
//       <Content>
//         <div className={"d-flex flex-column h-100 dark"}>
//           <AutocompleteInput
//             type={"systems"}
//             label={"Search for a system"}
//             placeholder={"Jita"}
//             value={selectedSystem}
//             onChange={setSelectedSystem}
//           />
//           <UniverseMap
//             width="100%"
//             height="100%"
//             selectedSystem={selectedSystem?.id}
//           />
//         </div>
//       </Content>
//     </Page>
//   )
// }
